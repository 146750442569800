<template>
  <AppHeaderBase :menu="menu">
    <template #append>
      <div class="flex items-center gap-2">
        <AppSearch variant="tonal" />
        <CartBtn variant="text" density="comfortable" />
      </div>
    </template>
  </AppHeaderBase>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AppHeaderBase from "./AppHeaderBase.vue";
import { defineAppMenu } from "./lib";
import AppSearch from "@/components/elements/search/AppSearch.vue";
import CartBtn from "../modules/CartBtn.vue";

export default defineComponent({
  name: "DefaultAppHeader",
  components: { AppHeaderBase, AppSearch, CartBtn },
  setup() {
    const menu = defineAppMenu({
      prepend: [
        { text: "Events", to: { name: "events" } },
        { text: "Services", to: { name: "services" } },
      ],
    });

    return {
      menu,
    };
  },
});
</script>
